jQuery(document).ready(function($) {
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		offCanvas: {
			position: "top",
			zposition: "front"
		},
		slidingSubmenus: false
	}).on( "closed.mm", function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	$mobileMenuTrigger.click(function() {
		$mobileMenu.trigger("open.mm");
		$mobileMenuTrigger.toggleClass("w--open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".content-div").fitVids({ ignore: "nofit" });
	}

	$('.main-body-content-wrap iframe').wrap('<div class="iframe-container">')


	$('a > img').parent().css('border-bottom','0')
});
